import axios from 'axios';
import { notification } from 'antd';
import { refreshToken, logOut } from '../redux/authentication/actionCreator';
import store from '../redux/store';

const API = axios.create({ baseURL: process.env.REACT_APP_API_BACKEND_URL });

let showNotification = false;

API.interceptors.request.use(req => {
  if (localStorage.getItem('user')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('user')).token}`;
    req.headers.refreshToken = JSON.parse(localStorage.getItem('user')).refreshToken;
  }

  return req;
});

API.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;
    // const dispatch = useDispatch();

    if (originalConfig.url !== '/api/user/signin' && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await API.post('/api/user/refreshToken');

          if (rs.data.error_code === 401) {
            if (!showNotification) {
              notification.error({
                message: 'Błąd',
                description: rs.data.message,
              });

              showNotification = true;
            }

            setTimeout(function() {
              showNotification = false;
            }, 5000);

            store.dispatch(logOut());
            return Promise.reject(err);
          }
          store.dispatch(refreshToken(rs.data));
          return API(originalConfig);
        } catch (_err) {
          return Promise.reject(_err);
        }
      }
    }

    return Promise.reject(err);
  },
);

export const setSetting = data =>
  API.post('/api/settings/set', data).catch(error => {
    return error.response;
  });
export const getSetting = data =>
  API.post('/api/settings/get', data).catch(error => {
    return error.response;
  });

// Authentication and authorization.
export const signIn = formData =>
  API.post('/api/user/signin', formData).catch(error => {
    return error.response;
  });

// Menu and pages.
export const addMenu = formData =>
  API.post('/api/menu/create', formData).catch(error => {
    return error.response;
  });
export const getMenu = params =>
  API.get('/api/menu/get', { params: { ...params, isBackend: true } }).catch(error => {
    return error.response;
  });
export const deleteMenu = id =>
  API.post('/api/menu/delete', { id }).catch(error => {
    return error.response;
  });
export const updateMenu = formData =>
  API.post('/api/menu/update', formData).catch(error => {
    return error.response;
  });
export const savePositions = formData =>
  API.post('/api/menu/pos', formData).catch(error => {
    return error.response;
  });

// Clients.
export const addClient = formData =>
  API.post('/api/client/create', formData).catch(error => {
    return error.response;
  });
export const getClientsList = params =>
  API.post('/api/client/list', params).catch(error => {
    return error.response;
  });
export const deleteClient = id =>
  API.post('/api/client/delete', { id }).catch(error => {
    return error.response;
  });
export const updateClient = formData =>
  API.post('/api/client/update', formData).catch(error => {
    return error.response;
  });
export const resetClientPassword = email =>
  API.post('/api/client/sendResetPassword', { email }).catch(error => {
    return error.response;
  });

// Users.
export const addUser = formData =>
  API.post('/api/user/create', formData).catch(error => {
    return error.response;
  });
export const getUsersList = params =>
  API.post('/api/user/list', params).catch(error => {
    return error.response;
  });
export const deleteUser = id =>
  API.post('/api/user/delete', { id }).catch(error => {
    return error.response;
  });
export const updateUser = formData =>
  API.post('/api/user/update', formData).catch(error => {
    return error.response;
  });
export const resetPassword = email =>
  API.post('/api/user/resetPassword', { email }).catch(error => {
    return error.response;
  });

// Timetable.
export const addMatch = formData =>
  API.post('/api/match/create', formData).catch(error => {
    return error.response;
  });
export const getTimetableList = params =>
  API.get('/api/stats/timetable', { params }).catch(error => {
    return error.response;
  });
export const deleteMatch = id =>
  API.post('/api/match/delete', { id }).catch(error => {
    return error.response;
  });
export const updateMatch = formData =>
  API.post('/api/match/update', formData).catch(error => {
    return error.response;
  });

// Players.
export const addPlayer = formData =>
  API.post('/api/players/create', formData).catch(error => {
    return error.response;
  });
export const getPlayersList = params =>
  API.post('/api/players/list', params).catch(error => {
    return error.response;
  });
export const deletePlayer = id =>
  API.post('/api/players/delete', { id }).catch(error => {
    return error.response;
  });
export const updatePlayer = formData =>
  API.post('/api/players/update', formData).catch(error => {
    return error.response;
  });
export const selectPlayerSeason = (id, seasonId) =>
  API.post('/api/players/select', { id, season: seasonId }).catch(error => {
    return error.response;
  });

export const getOptaPlayers = params =>
  API.get('/api/opta/players/list', { params }).catch(error => {
    return error.response;
  });

// Matchroom.
export const addMatchroom = formData =>
  API.post('/api/matchroom/create', formData).catch(error => {
    return error.response;
  });
export const getMatchroomList = params =>
  API.get('/api/matchroom/list', params).catch(error => {
    return error.response;
  });
export const deleteMatchroom = id =>
  API.post('/api/matchroom/delete', { id }).catch(error => {
    return error.response;
  });
export const updateMatchroom = formData =>
  API.post('/api/matchroom/update', formData).catch(error => {
    return error.response;
  });
export const getOneMatchroom = id =>
  API.post('/api/matchroom/getone', { id }).catch(error => {
    return error.response;
  });

export const savePlayer = player =>
  API.post('/api/matchroom/savePlayer', player).catch(error => {
    return error.response;
  });
export const matchroomControl = params =>
  API.post('/api/matchroom/control', params).catch(error => {
    return error.response;
  });
export const pushRelation = relation =>
  API.post('/api/matchroom/relation/save', relation).catch(error => {
    return error.response;
  });
export const deleteMatchroomPlayer = id =>
  API.post('/api/matchroom/player/delete', { id }).catch(error => {
    return error.response;
  });
export const deleteMatchroomRelation = id =>
  API.post('/api/matchroom/relation/delete', { id }).catch(error => {
    return error.response;
  });

// Tickets.
export const getPriceList = params =>
  API.get('/api/ticketprices/c/get', params).catch(error => {
    return error.response;
  });

export const addPriceList = formData =>
  API.post('/api/ticketprices/pricelist/create', formData).catch(error => {
    return error.response;
  });
export const deletePriceList = id =>
  API.post('/api/ticketprices/pricelist/delete', { id }).catch(error => {
    return error.response;
  });
export const updatePriceList = formData =>
  API.post('/api/ticketprices/pricelist/edit', formData).catch(error => {
    return error.response;
  });
//
export const addPriceElement = formData =>
  API.post('/api/ticketprices/element/create', formData).catch(error => {
    return error.response;
  });
export const deletePriceElement = id =>
  API.post('/api/ticketprices/element/delete', { id }).catch(error => {
    return error.response;
  });
export const updatePriceElement = formData =>
  API.post('/api/ticketprices/element/edit', formData).catch(error => {
    return error.response;
  });

// Teams.
export const addTeam = formData =>
  API.post('/api/teams/create', formData).catch(error => {
    return error.response;
  });
export const getTeamsList = params =>
  API.get('/api/teams/list', params).catch(error => {
    return error.response;
  });
export const deleteTeam = id =>
  API.post('/api/teams/delete', { id }).catch(error => {
    return error.response;
  });
export const updateTeam = formData =>
  API.post('/api/teams/update', formData).catch(error => {
    return error.response;
  });

export const getLeagueTeams = params =>
  API.get('/api/teams/league/list', params).catch(error => {
    return error.response;
  });

// JagaTV.
export const addMovie = formData =>
  API.post('/api/movies/create', formData).catch(error => {
    return error.response;
  });
export const getMoviesList = params =>
  API.get('/api/movies/list', params).catch(error => {
    return error.response;
  });
export const deleteMovie = id =>
  API.post('/api/movies/delete', { id }).catch(error => {
    return error.response;
  });
export const updateMovie = formData =>
  API.post('/api/movies/update', formData).catch(error => {
    return error.response;
  });

// Seasons.
export const addSeason = formData =>
  API.post('/api/seasons/create', formData).catch(error => {
    return error.response;
  });
export const getSeasonsList = params =>
  API.get('/api/seasons/list', params).catch(error => {
    return error.response;
  });
export const deleteSeason = id =>
  API.post('/api/seasons/delete', { id }).catch(error => {
    return error.response;
  });
export const activateSeason = formData =>
  API.post('/api/seasons/activate', formData).catch(error => {
    return error.response;
  });

// MEDIA
export const addFiles = formData =>
  API.post('/api/media/files/upload', formData).catch(error => {
    return error.response;
  });
export const getFileList = params =>
  API.post('/api/media/files/list', params).catch(error => {
    return error.response;
  });
export const addFolder = name =>
  API.post('/api/media/folder/create', { name }).catch(error => {
    return error.response;
  });
export const getFolderList = params =>
  API.post('/api/media/folder/list', params).catch(error => {
    return error.response;
  });
export const deleteFile = id =>
  API.post('/api/media/files/delete', { id }).catch(error => {
    return error.response;
  });

// News and categories.
export const getCategories = params =>
  API.post('/api/news/c/categories/get', params).catch(error => {
    return error.response;
  });
export const addCategory = (name, type) =>
  API.post('/api/news/c/categories/add', { name, type }).catch(error => {
    return error.response;
  });
export const addNews = formData =>
  API.post('/api/news/c/add', formData).catch(error => {
    return error.response;
  });
export const getNews = params =>
  API.post('/api/news/c/get', params).catch(error => {
    return error.response;
  });
export const deleteNews = id =>
  API.post('/api/news/c/delete', { id }).catch(error => {
    return error.response;
  });
export const getOneNews = id =>
  API.post('/api/news/c/edit/get', { id }).catch(error => {
    return error.response;
  });
export const editNews = (id, formData) =>
  API.post('/api/news/c/edit', { id, formData }).catch(error => {
    return error.response;
  });

// Pages.
export const addPage = formData =>
  API.post('/api/pages/add', formData).catch(error => {
    return error.response;
  });
export const getPages = params =>
  API.get('/api/pages/c/get', { params }).catch(error => {
    return error.response;
  });
export const deletePage = id =>
  API.post('/api/pages/delete', { id }).catch(error => {
    return error.response;
  });
export const getOnePage = id =>
  API.post('/api/pages/edit/get', { id }).catch(error => {
    return error.response;
  });
export const editPage = (id, formData) =>
  API.post('/api/pages/edit', { id, formData }).catch(error => {
    return error.response;
  });
