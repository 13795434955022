import { notification } from 'antd';

const sendNotification = (status, message) => {
  notification[status]({
    message: status == 'success' ? 'Udało się' : 'Błąd',
    description: message,
  });
};

const actions = {
  ADD_MATCHROOM_SUCCESS: 'ADD_MATCHROOM_SUCCESS',
  ADD_MATCHROOM_ERR: 'ADD_MATCHROOM_ERR',
  ADD_MATCHROOM_BEGIN: 'ADD_MATCHROOM_BEGIN',

  GET_MATCHROOMS_BEGIN: 'GET_MATCHROOMS_BEGIN',
  GET_MATCHROOMS_ERR: 'GET_MATCHROOMS_ERR',
  GET_MATCHROOMS_SUCCESS: 'GET_MATCHROOMS_SUCCESS',

  UPDATE_MATCHROOM_BEGIN: 'UPDATE_MATCHROOM_BEGIN',
  UPDATE_MATCHROOM_SUCCESS: 'UPDATE_MATCHROOM_SUCCESS',
  UPDATE_MATCHROOM_ERR: 'UPDATE_MATCHROOM_ERR',

  DELETE_MATCHROOM_BEGIN: 'DELETE_MATCHROOM_BEGIN',
  DELETE_MATCHROOM_SUCCESS: 'DELETE_MATCHROOM_SUCCESS',
  DELETE_MATCHROOM_ERR: 'DELETE_MATCHROOM_ERR',

  GET_ONE_MATCHROOM_BEGIN: 'GET_ONE_MATCHROOM_BEGIN',
  GET_ONE_MATCHROOM_SUCCESS: 'GET_ONE_MATCHROOM_SUCCESS',
  GET_ONE_MATCHROOM_ERR: 'GET_ONE_MATCHROOM_ERR',

  SAVE_MATCHROOM_PLAYER_BEGIN: 'SAVE_MATCHROOM_PLAYER_BEGIN',
  SAVE_MATCHROOM_PLAYER_SUCCESS: 'SAVE_MATCHROOM_PLAYER_SUCCESS',
  SAVE_MATCHROOM_PLAYER_ERR: 'SAVE_MATCHROOM_PLAYER_ERR',

  MATCHROOM_CONTROL_BEGIN: 'MATCH_CONTROL_BEGIN',
  MATCHROOM_CONTROL_SUCCESS: 'MATCH_CONTROL_SUCCESS',
  MATCHROOM_CONTROL_ERR: 'MATCH_CONTROL_ERR',

  PUSH_RELATION_BEGIN: 'PUSH_RELATION_BEGIN',
  PUSH_RELATION_SUCCESS: 'PUSH_RELATION_SUCCESS',
  PUSH_RELATION_ERR: 'PUSH_RELATION_ERR',

  pushRelationBegin: () => {
    return {
      type: actions.PUSH_RELATION_BEGIN,
    };
  },
  pushRelationSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.PUSH_RELATION_SUCCESS,
      data,
      status,
    };
  },
  pushRelationErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.PUSH_RELATION_ERR,
      status,
    };
  },
  matchroomControlBegin: () => {
    return {
      type: actions.MATCHROOM_CONTROL_BEGIN,
    };
  },
  matchroomControlSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.MATCHROOM_CONTROL_SUCCESS,
      data,
      status,
    };
  },
  matchroomControlErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.MATCHROOM_CONTROL_ERR,
      status,
    };
  },
  addMatchroomBegin: () => {
    return {
      type: actions.ADD_MATCHROOM_BEGIN,
    };
  },
  addMatchroomErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_MATCHROOM_ERR,
      status,
    };
  },
  addMatchroomSuccess: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_MATCHROOM_SUCCESS,
      status,
    };
  },
  deleteMatchroomElementsBegin: () => {
    return {
      type: actions.ADD_MATCHROOM_BEGIN,
    };
  },
  deleteMatchroomElementsErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_MATCHROOM_ERR,
      status,
    };
  },
  deleteMatchroomElementsSuccess: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.ADD_MATCHROOM_SUCCESS,
      status,
    };
  },
  saveMatchroomPlayerBegin: () => {
    return {
      type: actions.SAVE_MATCHROOM_PLAYER_BEGIN,
    };
  },
  saveMatchroomPlayerSuccess: (data, status) => {
    if (status && !status.payload) sendNotification(status.status, status.message);
    return {
      type: actions.SAVE_MATCHROOM_PLAYER_SUCCESS,
      status: status || null,
      data,
    };
  },
  saveMatchroomPlayerErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.SAVE_MATCHROOM_PLAYER_ERR,
      status,
    };
  },
  getOneMatchroomBegin: () => {
    return {
      type: actions.GET_ONE_MATCHROOM_BEGIN,
    };
  },
  getOneMatchroomSuccess: (data, status) => {
    if (status && !status.payload) sendNotification(status.status, status.message);
    return {
      type: actions.GET_ONE_MATCHROOM_SUCCESS,
      status: status || null,
      data,
    };
  },
  getOneMatchroomErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_ONE_MATCHROOM_ERR,
      status,
    };
  },
  getMatchroomListBegin: () => {
    return {
      type: actions.GET_MATCHROOMS_BEGIN,
    };
  },
  getMatchroomListErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.GET_MATCHROOMS_ERR,
      status,
    };
  },
  getMatchroomListSuccess: data => {
    return {
      type: actions.GET_MATCHROOMS_SUCCESS,
      data,
    };
  },
  updateMatchroomBegin: () => {
    return {
      type: actions.UPDATE_MATCHROOM_BEGIN,
    };
  },
  updateMatchroomSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_MATCHROOM_SUCCESS,
      data,
      status,
    };
  },
  updateMatchroomErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.UPDATE_MATCHROOM_ERR,
      status,
    };
  },
  deleteMatchroomBegin: () => {
    return {
      type: actions.DELETE_MATCHROOM_BEGIN,
    };
  },
  deleteMatchroomSuccess: (data, status) => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_MATCHROOM_SUCCESS,
      data,
      status,
    };
  },
  deleteMatchroomErr: status => {
    if (!status.payload) sendNotification(status.status, status.message);

    return {
      type: actions.DELETE_MATCHROOM_ERR,
      status,
    };
  },
};

export default actions;
