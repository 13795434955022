import actions from './actions';

const {
  ADD_MATCHROOM_BEGIN,
  ADD_MATCHROOM_ERR,
  ADD_MATCHROOM_SUCCESS,
  GET_MATCHROOMS_SUCCESS,
  GET_MATCHROOMS_BEGIN,
  GET_MATCHROOMS_ERR,
  GET_ONE_MATCHROOM_SUCCESS,
  GET_ONE_MATCHROOM_BEGIN,
  GET_ONE_MATCHROOM_ERR,
  UPDATE_MATCHROOM_BEGIN,
  UPDATE_MATCHROOM_SUCCESS,
  UPDATE_MATCHROOM_ERR,
  DELETE_MATCHROOM_BEGIN,
  DELETE_MATCHROOM_SUCCESS,
  DELETE_MATCHROOM_ERR,
  SAVE_MATCHROOM_PLAYER_BEGIN,
  SAVE_MATCHROOM_PLAYER_SUCCESS,
  SAVE_MATCHROOM_PLAYER_ERR,
  MATCHROOM_CONTROL_BEGIN,
  MATCHROOM_CONTROL_SUCCESS,
  MATCHROOM_CONTROL_ERR,
  PUSH_RELATION_BEGIN,
  PUSH_RELATION_SUCCESS,
  PUSH_RELATION_ERR,
} = actions;

const initState = {
  loading: false,
  loadingSavePlayer: false,
  loadingControl: false,
  loadingRelation: false,
  list: [],
  status: null,
  toEdit: null,
  loadingEdit: false,
};

const MatchroomReducer = (state = initState, action) => {
  const { type, data, status } = action;

  switch (type) {
    case PUSH_RELATION_BEGIN: {
      return {
        ...state,
        loadingRelation: true,
      };
    }
    case PUSH_RELATION_ERR: {
      return {
        ...state,
        loadingRelation: false,
        status,
      };
    }
    case PUSH_RELATION_SUCCESS: {
      return {
        ...state,
        loadingRelation: false,
        toEdit: data,
        status,
      };
    }
    case MATCHROOM_CONTROL_BEGIN: {
      return {
        ...state,
        loadingControl: true,
      };
    }
    case MATCHROOM_CONTROL_ERR: {
      return {
        ...state,
        loadingControl: false,
        status,
      };
    }
    case MATCHROOM_CONTROL_SUCCESS: {
      return {
        ...state,
        loadingControl: false,
        toEdit: data,
        status,
      };
    }
    case ADD_MATCHROOM_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_MATCHROOM_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case ADD_MATCHROOM_SUCCESS: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case GET_MATCHROOMS_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_MATCHROOMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status: null,
      };
    }
    case GET_MATCHROOMS_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case SAVE_MATCHROOM_PLAYER_BEGIN: {
      return {
        ...state,
        loadingSavePlayer: true,
      };
    }
    case SAVE_MATCHROOM_PLAYER_SUCCESS: {
      return {
        ...state,
        loadingSavePlayer: false,
        toEdit: data,
        status: null,
      };
    }
    case SAVE_MATCHROOM_PLAYER_ERR: {
      return {
        ...state,
        loadingSavePlayer: false,
        toEdit: null,
        status,
      };
    }
    case GET_ONE_MATCHROOM_BEGIN: {
      return {
        ...state,
        loadingEdit: true,
      };
    }
    case GET_ONE_MATCHROOM_SUCCESS: {
      return {
        ...state,
        loadingEdit: false,
        toEdit: data,
        status: null,
      };
    }
    case GET_ONE_MATCHROOM_ERR: {
      return {
        ...state,
        loadingEdit: false,
        toEdit: null,
        status,
      };
    }
    case UPDATE_MATCHROOM_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_MATCHROOM_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case UPDATE_MATCHROOM_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    case DELETE_MATCHROOM_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_MATCHROOM_SUCCESS: {
      return {
        ...state,
        loading: false,
        list: data,
        status,
      };
    }
    case DELETE_MATCHROOM_ERR: {
      return {
        ...state,
        loading: false,
        status,
      };
    }
    default:
      return state;
  }
};

export default MatchroomReducer;
